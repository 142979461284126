import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { HiOutlineMail } from "@react-icons/all-files/hi/HiOutlineMail";
import styled from "styled-components";
import Mailto from 'react-protected-mailto'

const AboutPage = () => {
  return (
    <Layout pageTitle="about" desc="Read about Michael Raymond Lund">
      <StaticImage
        alt="portrait photo of michael raymond lund"
        src="../images/mike_portrait.jpg"
        // width={250}
        height={250}
        imgStyle={{ borderRadius: '5%' }}
      />
      <AboutDescription>Through the plundering of the conceptual tropes of post-modern painting and the use of demotic imagery, I explore the limits and possibilities of abstraction. From cheap markers to oil paint, my use of disparate mediums creates an inherent visual discord. The unresolved contradictions and juxtapositions of my paintings reveal themselves matter-of-factly, elevating the value of process and intention equally to that of the image in itself. I avoid steely indifference and nihilism by finding edge in the playful and self-evidently joyus act of mark-making; the tactility of creating images.</AboutDescription>
      <AboutFooter>
      <LinksSpan>
          <AboutDescription>For inquiries:</AboutDescription>
          <Mailto email='mrlundo@gmail.com' />
        </LinksSpan>
        <LinksSpan>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/michael_raymond_lund/">
            <InstaLogo size = {42}/>
          </a>
        </LinksSpan>
      </AboutFooter>
    </Layout>
  )
}

export default AboutPage

const AboutDescription = styled.p`
  padding: 0.3rem;
  font-size: 16px;
  font-size: clamp(16px, 2vw, 20px);
`

const InstaLogo = styled(FaInstagram)`
    color:black;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: gray;
    }
`

const MailLogo = styled(HiOutlineMail)`
    color:black;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: gray;
    }
`

const AboutFooter = styled.footer`
    padding-top: 20px;
`

const LinksSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    a{
      font-size: 16px;
      font-size: clamp(16px, 2vw, 20px);
    }

    a:link {
      color: black;
    }

    a:visited {
      color: darkgray;
    }

    a:hover {
      color: gray;
    }
`